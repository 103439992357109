import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from 'react-router';
import DynamicForm from './Component/DynamicForm';

function App() {
  return (
    <div className="App">
      <div className="App-header">
       <Routes>
         <Route path='/' element={<DynamicForm/>}/>
       </Routes>
      </div>
    </div>
  );
}

export default App;
