import axios from 'axios'
import React, { useEffect } from 'react'
import { Form } from 'react-bootstrap'

const data = {
    "headers": {
        "required": [
            [
                "name",
                "string"
            ],
            [
                "age",
                "integer"
            ]
        ],
        "optional": [
            [
                "surname",
                "string"
            ]
        ]
    },
    "query_params": {
        "required": [
            "admin_code",
            "email"
        ],
        "optional": [
            "editor_limit",
            "device_limit"
        ]
    },
    "body_params": {
        "required": [
            [
                "name",
                "string"
            ],
            [
                "age",
                "integer"
            ]
        ],
        "optional": [
            [
                "surname",
                "string"
            ]
        ]
    }
}
const DynamicForm = () => {
    const [devData, setDevData] = React.useState()
    const [requiredData, setRequiredData] = React.useState()
    const [requiredField, setRequiredField] = React.useState({})
    const [optionalField, setOptionalField] = React.useState()
    const [message, setMessage] = React.useState()
    const [data, setData] = React.useState()
    const [bodyRequiredField, setBodyRequiredField] = React.useState()
    const [bodyOptionalField, setBodyOptionalField] = React.useState()
    const [headerRequredField, setHeaderRequiredField] = React.useState()
    const [headerOptionalField, setHeaderOptionalField] = React.useState()
    const [error, setError] = React.useState()
    
    const selectApiType = (e) => {
        if (e.target.value === 'dev') {
            axios({
                url: 'https://sy5rrkcfih44zbt7y6phfd3qse0krcpn.lambda-url.ap-south-1.on.aws/?admin_env=dev',
                // configuration
            })
                .then(response => {
                    setDevData(response.data.apis)
                    setRequiredField('')
                    setOptionalField('')
                    setBodyRequiredField('')
                    setBodyOptionalField('')
                    setHeaderRequiredField('')
                    setHeaderOptionalField('')
                    setMessage('')
                    setError('')
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        else {
            axios({
                url: 'https://sy5rrkcfih44zbt7y6phfd3qse0krcpn.lambda-url.ap-south-1.on.aws/?admin_env=prod',
                // configuration
            })
                .then(response => {
                    setDevData(response.data.apis)
                    setRequiredField('')
                    setOptionalField('')
                    setBodyRequiredField('')
                    setBodyOptionalField('')
                    setHeaderRequiredField('')
                    setHeaderOptionalField('')
                    setMessage('')
                    setError('')
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }
    const selectApi = (e) => {
        const data = devData.filter((item) =>
            item.url === e.target.value
        )
        setRequiredData(data[0])
    }
    
    const params = []
    const required = []
    const getApiCall = (requiredField, optionalField, bodyRequiredField, bodyOptionalField, headerRequredField, headerOptionalField) => {
        // Object.keys(requiredField).map((item) => {
        //     required.push((item + '=' + requiredField[item]))
        //     return (params.push(item + '=' + requiredField[item]))
        // })

        if (requiredField) {
        Object.keys(requiredField).map((item) => {
            if (requiredField[item] !== "") {
            required.push((item + '=' + requiredField[item]))
            return (params.push(item + '=' + requiredField[item]))
            }
        })
    }
        if (optionalField) {
            Object.keys(optionalField).map((item) => {
                if (optionalField[item] !== "") {
                    params.push(item + '=' + optionalField[item])
                }
            })
        }
        if (bodyRequiredField) {
            Object.keys(bodyRequiredField).map((item) => {
                if (bodyRequiredField[item] !== "") {
                    params.push(item + '=' + bodyRequiredField[item])
                }
            })
        }
        if (bodyRequiredField) {
            Object.keys(bodyRequiredField).map((item) => {
                if (bodyRequiredField[item] !== "") {
                    params.push(item + '=' + bodyRequiredField[item])
                }
            })
        }
        if (bodyOptionalField) {
            Object.keys(bodyOptionalField).map((item) => {
                if (bodyOptionalField[item] !== "") {
                    params.push(item + '=' + bodyOptionalField[item])
                }
            })
        }
        if (headerRequredField) {
            Object.keys(headerRequredField).map((item) => {
                if (headerRequredField[item] !== "") {
                    params.push(item + '=' + headerRequredField[item])
                }
            })
        }
        if (headerOptionalField) {
            Object.keys(headerOptionalField).map((item) => {
                if (headerOptionalField[item] !== "") {
                    params.push(item + '=' + headerOptionalField[item])
                }
            })
        }

        return params.join('&');
    }

    const getUriParams = getApiCall(requiredField, optionalField, bodyRequiredField, bodyOptionalField, headerRequredField, headerOptionalField)
    const runApi = () => {
        axios({
            url: `${requiredData?.url}?${getUriParams}`,
        })
            .then(response => {
                setMessage(response.data.message)
                setData(JSON.stringify(response.data.data))

            })
            .catch((error) => {
                setError(error.response.data.message)
            })

    }

    const handleChangeRequiredData = (e) => {
        setRequiredField({ ...requiredField, [e.target.name]: e.target.value })
    }
    const handleChangeoptionalData = (e) => {
        setOptionalField({ ...optionalField, [e.target.name]: e.target.value })
    }
    const handleBodyChangeRequiredData = (e) => {
        setBodyRequiredField({ ...bodyRequiredField, [e.target.name]: e.target.value })
    }
    const handleBodyChangeOptionalData = (e) => {
        setBodyOptionalField({ ...bodyOptionalField, [e.target.name]: e.target.value })
    }
    const handleChangeHeaderRequiredData = (e) => {
        setHeaderRequiredField({ ...headerRequredField, [e.target.name]: e.target.value })
    }
    const handleChangeHeaderOptionaldData = (e) => {
        setHeaderOptionalField({ ...headerOptionalField, [e.target.name]: e.target.value })
    }
    
    return (
        <div>
            <Form>
                <div className='d-flex justify-content-between mb-3'>
                    <Form.Label className='w-50 d-flex justify-content-start'>Select the API environment:</Form.Label>
                    <Form.Select aria-label="Default select example" onChange={(e) => selectApiType(e)} className="select">
                        <option disabled selected>Open this select menu</option>
                        <option value="dev">dev</option>
                        <option value="prod">Prod</option>
                    </Form.Select>
                </div>
            </Form>
            {devData &&
                <>
                    <p style={{ textAlign: 'start', fontWeight: 'bold' }}>Choose any API:</p>
                    <Form>
                        <div className='d-flex justify-content-between mb-3 w-100'>
                            <Form.Label className='w-50'>API Name:</Form.Label>
                            <Form.Select aria-label="Default select example" onChange={(e) => selectApi(e)}>
                                <option disabled selected>Open api</option>
                                {
                                    devData?.map((item) => {
                                        return (
                                            <>
                                                <option value={item.url}>{item.name}</option>
                                            </>
                                        )
                                    })
                                }
                            </Form.Select>
                        </div>
                    </Form>
                    {requiredData && <Form >
                        <div className='d-flex justify-content-between mb-3 w-100'>
                            <Form.Label className='w-50'>Description:</Form.Label>
                            {requiredData?.desc && <p>{requiredData?.desc}</p>}
                        </div>
                    </Form>}
                    {requiredData&& <h4 style={{ textAlign: 'start' }}>Header Parameters:</h4>}
                    {requiredData && Object.entries(requiredData?.headers).length === 0 &&
                        <Form>
                            {
                                requiredData && <div className='d-flex justify-content-between mb-3'>
                                    <Form.Label className='w-50'> Header Parameters :</Form.Label>
                                    <Form.Control value="No Data" disabled />
                                </div>
                            }
                        </Form>}
                    {requiredData?.headers?.required && <>
                        <p style={{ textAlign: 'start', fontWeight: 'bold' }}>Required Parameters:</p>
                        <Form>
                            {
                                requiredData?.headers.required.map((item, i) => {
                                    return (
                                        <div className='d-flex justify-content-between mb-3'>
                                            <Form.Label className='w-50'>{item} </Form.Label>
                                            <Form.Control name={item} onChange={(e) => handleChangeHeaderRequiredData(e)} />
                                        </div>
                                    )
                                })
                            }
                        </Form>
                    </>}
                    {requiredData?.headers?.optional?.length > 0 &&
                        <>
                            <p style={{ textAlign: 'start', fontWeight: 'bold' }}>Optional Parameters:</p>
                            <Form>
                                {
                                    requiredData?.headers.optional.map((item) => {
                                        return (
                                            <div className='d-flex justify-content-between mb-3'>
                                                <Form.Label className='w-50'>{item} </Form.Label>
                                                <Form.Control name={item} onChange={(e) => handleChangeHeaderOptionaldData(e)} />
                                            </div>
                                        )
                                    })
                                }
                            </Form>
                        </>
                    }
                    {requiredData?.query_params?.required &&
                        <div>
                            <h5>Query Parameters</h5>
                            <p style={{ textAlign: 'start', fontWeight: 'bold' }}>Required Parameters:</p>
                            <Form>
                                {
                                    requiredData?.query_params?.required?.map((item, i) => {
                                        return (
                                            <div className='d-flex justify-content-between mb-3'>
                                                <Form.Label className='w-50'>{item}:</Form.Label>
                                                <Form.Control name={item} onChange={(e) => handleChangeRequiredData(e)} />
                                            </div>
                                        )
                                    })
                                }
                            </Form>
                        </div>}
                    {requiredData?.query_params?.optional.length > 0 && <div className='mt-3'>
                        <p style={{ textAlign: 'start', fontWeight: 'bold' }}>Optional Parameters:</p>
                        <Form>
                            {
                                requiredData?.query_params?.optional?.map((item, i) => {
                                    return (
                                        <div className='d-flex justify-content-between mb-3'>
                                            <Form.Label className='w-50'>{item}:</Form.Label>
                                            <Form.Control name={item} onChange={(e) => handleChangeoptionalData(e)} />
                                        </div>
                                    )
                                })
                            }
                        </Form>
                    </div>}
                    {requiredData && Object.entries(requiredData?.body_params).length === 0 && <div className='mt-5'>
                        <Form>
                            {requiredData && <div className='d-flex justify-content-between mb-3'>
                                <Form.Label className='w-50'>Body Parameters:</Form.Label>
                                <Form.Control value='No Data' disabled />
                            </div>}
                        </Form>
                    </div>}
                    {requiredData?.body_params?.required?.length > 0 && <div className='mt-5'>
                        <h5>Body Parameters</h5>
                        <p style={{ textAlign: 'start', fontWeight: 'bold' }}>Required Parameters:</p>
                        <Form>
                            {requiredData?.body_params?.required.map((item) => {
                                return (
                                    <div className='d-flex justify-content-between mb-3'>
                                        <Form.Label className='w-50'>{item}:</Form.Label>
                                        <Form.Control name={item} onChange={(e) => handleBodyChangeRequiredData(e)} />
                                    </div>
                                )
                            })}
                        </Form>
                    </div>}
                    {requiredData?.body_params?.optional?.length > 0 && <div className='mt-5'>
                        <p style={{ textAlign: 'start', fontWeight: 'bold' }}>Optional Parameters:</p>
                        <Form>
                            {requiredData?.body_params?.optional.map((item) => {
                                return (
                                    <div className='d-flex justify-content-between mb-3'>
                                        <Form.Label className='w-50'>{item}:</Form.Label>
                                        <Form.Control name={item} onChange={(e) => handleBodyChangeOptionalData(e)} />
                                    </div>
                                )
                            })}
                        </Form>
                    </div>}
                </>
            }

            <button disabled={requiredData?.query_params?.required?.length !== required?.length} onClick={runApi}> Run Api</button>

            {
                error && <p className='errormessage'>{error}</p>
            }
            {
                message && data && <p className='message'>{message + " " + data}</p>
            }
            {
                message && !data && <p className='message'>{message}</p>
            }
        </div >
    )
}

export default DynamicForm